import {
    useState
} from "react"
import Image from "next/image"
import Link from "next/link"
import { INavLink } from "../../utils/types"
import useWindowDimensions from "../../utils/hooks"
import ThemeMap from '../../styles/themes'
import { useRouter } from "next/router"
import useUser from "../../utils/useUser"
import { User } from "../../pages/api/user"
import fetchJson from "../../utils/fetchJson"

interface Navbar {
    theme: string,
    links: INavLink[],
    isDisabled: boolean,
    user?: User
}

interface NavbarProps {
    navbar: Navbar
}

const logos = {
    color: "https://res.cloudinary.com/polymath-ventures/image/upload/v1642045769/Logo_color_PV_n5bnuk.png",
    white: "https://res.cloudinary.com/polymath-ventures/image/upload/v1642045953/Logo_en_blanco_-_PV_tpdmww.png",
    blue: "https://res.cloudinary.com/polymath-ventures/image/upload/v1642045901/Logo_oscuro_PV_cxmn2i.png"
}

const Navbar = ({ navbar }: NavbarProps) => {
    const [mobileNavExpanded, setMobileNavExpanded] = useState<boolean>(false)
    const { width } = useWindowDimensions();
    const router = useRouter()
    const { user, mutateUser } = useUser()

    const currentTheme = ThemeMap[navbar.theme]

    const classes = `flex z-[99] ${currentTheme.primary} text-white ${navbar.isDisabled === true ? 'justify-center' : 'justify-between'} items-center px-10 py-2 navbar`

    return (
        <nav className={classes}>
            {navbar.isDisabled === true ? 
                <div className={`z-[999] relative`}>
                    <Image
                        src={(navbar.theme !== "white") ? logos.white : logos.color}
                        alt="Polymath"
                        height={38}
                        width={138}
                    />
                </div>
                :
                <Link href='/'>
                    <a className={`z-[999] ${mobileNavExpanded ? 'fixed top-2 left-10' : 'relative'} md:relative`}>
                        <Image
                            src={(navbar.theme !== "white") ? logos.white : logos.color}
                            alt="Polymath"
                            height={38}
                            width={138}
                        />
                    </a>
                </Link>
            }
            {navbar.isDisabled !== true && <button 
                name="menu"
                aria-label="menu toggle"
                aria-controls="primary-navigation" 
                aria-expanded={mobileNavExpanded || (width && width >768)}
                id="nav-icon"
                className={`${mobileNavExpanded ? 'open fixed' : 'absolute'} block md:hidden top-4 right-5 z-[999]`}
                onClick={() => setMobileNavExpanded(!mobileNavExpanded)}
            >
                <span></span>
                <span></span>
                <span></span>
            </button>}
            {navbar.isDisabled !== true && <ul 
                id="primary-navigation"
                data-visible={mobileNavExpanded || (width && width >= 768)}
                className={`transition ease-in-out flex ${currentTheme.secondary} pl-10 pt-20 md:p-0 fixed md:relative flex-col  md:flex-row right-0 md:right-auto inset-y-0 md:inset-y-auto w-full md:w-auto z-[995] gap-5 md:gap-10 text-sm ${mobileNavExpanded && "active"}`}
            >
                {navbar.links.map((link, index) => (
                    <li key={index} className={`item ${navbar.theme === 'transparent' ? 'text-white' : currentTheme.textPrimary} ${(index === (navbar.links.length - 2)) ? 'item-last' :''}`} style={(index === (navbar.links.length - 2)) ? { paddingLeft: '2rem', borderLeft: navbar.theme !== 'white' ? 'solid 1px white' : 'solid 1px #1F1A33'} : {}}>
                        <Link href={link.url}>
                            <a
                                className="uppercase text-[1.8rem] md:text-sm md:py-2 font-[800]"
                                onClick={() => mobileNavExpanded && (width && width <= 768) && setMobileNavExpanded(false)}
                            >
                                {link.text}
                            </a>
                        </Link>
                    </li>
                ))}
                {navbar.user?.isLoggedIn &&
                    <li
                        className="text-primary-red uppercase text-[1.8rem] md:text-sm  font-[800]"
                    >
                        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                        <a
                            href="/api/logout"
                            onClick={async (e) => {
                                e.preventDefault();
                                mutateUser(
                                    await fetchJson("/api/logout", { method: "POST" }),
                                    false
                                );
                                router.push("/login");
                            }}
                        >
                            Log Out
                        </a>
                    </li>
                }
            </ul>}
        </nav>
    )
}

export default Navbar

