import React from 'react'
import { FooterData, IComponentProps } from '../../utils/types'
import { FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa'
import { AiOutlineTwitter, AiOutlineInstagram, AiOutlineGithub } from 'react-icons/ai'
import Link from 'next/link'
import NewsLetter from '../sections/NewsLetter'

interface IIconMap {
    [key: string]: React.ReactNode
  }

const iconMap: IIconMap = {
    LinkedIn: <FaLinkedinIn size={25} color="white" />,
    Github: <AiOutlineGithub size={25} color="white" />,
    Twitter: <AiOutlineTwitter size={25} color="white" />,
    Facebook: <FaFacebook size={25} color="white" />,
    Instagram: <FaInstagram size={25} color="white" />,
  }


const Footer = ({ data }: IComponentProps<FooterData>) => {
    return (
        <footer>
            <div className='bg-primary-blue text-white py-10 px-10 md:px-[5rem] lg:px-[10rem] md:pt-[3rem] md:pb-[7rem]'>
                <div className="flex flex-col md:flex-row gap-20 md:gap-10 text-[1.3rem]">
                    <div className='flex flex-col gap-5 w-full md:w-[50%]'>
                        <div className='w-full flex gap-10'>
                            <ul className='w-1/3 list-none flex flex-col items-center md:items-start gap-2'>
                                {data.pageLinks.slice(0, 4).map((link, index) => (
                                    <li key={index}>
                                        <Link href={link.url}>
                                            <a>{link.text}</a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <ul className='w-1/3 list-none flex flex-col items-center md:items-start gap-2'>
                                {data.pageLinks.slice(4, 7).map((link, index) => (
                                    <li key={index}>
                                        <Link href={link.url}>
                                            <a>{link.text}</a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <ul className='w-1/3 list-none flex flex-col items-center md:items-start gap-2'>
                                {data.pageLinks.slice(7, 9).map((link, index) => (
                                    <li key={index}>
                                        <Link href={link.url}>
                                            <a target="_blank">{link.text}</a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='w-full md:w-[25%]'>
                        <div className="contact-form">
                            <h3 className='text-[1.9rem] xs:text-center md:text-left font-bold mb-10'>Join Our Mailing List</h3>
                            <NewsLetter />
                        </div>
                    </div>
                    <div className='w-full md:w-[25%] flex justify-center md:justify-end'>
                        <div className="socials flex flex-col gap-5 items-center md:items-start">
                            <ul className='flex gap-5'>
                                {data.socials.map((item, index) => (
                                    <li  key={index}>
                                        <Link href={item.URL}>
                                            <a className='relative' target="_blank" aria-hidden="true">
                                                {iconMap[item.Icon]}
                                                <span className='sr-only'>{item.Title}</span>
                                            </a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <span className="contact-email text-sm font-light">
                                hola@polymathv.com
                            </span>
                            <span className="terms-of-service text-sm text-center md:text-left font-light">
                                <Link href="/terms-of-use"><a className='text-primary-green hover:underline'>Terms of use</a></Link> & <Link href="/privacy-policy"><a className='text-primary-green hover:underline'>Privacy policy</a></Link>
                                <br />
                                © Polymath Ventures {new Date().getFullYear()}. 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
